import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import FooterDE from "../Symbols/FooterDE";
import "../../stylesheets/Kontakt.css";
import MetaTags from "react-meta-tags";
import News1 from "../../assets/blog_1.jpg";
import News2 from "../../assets/blog_2.jpg";
import News3 from "../../assets/blog_3.jpg";
import News4 from "../../assets/blog_4.jpg";
import News5 from "../../assets/blog_5.jpg";
import News6 from "../../assets/blog_6.jpg";
import News7 from "../../assets/blog_7.jpg";
import News8 from "../../assets/blog_8.jpg";
import News9 from "../../assets/blog_9.jpg";
import News10 from "../../assets/blog_10.jpg";
import News11 from "../../assets/blog_11.jpg";
import Art1 from "../../assets/news_1.jpg";
import Art2 from "../../assets/news_2.jpg";
import Art3 from "../../assets/news_3.jpeg";
import Art4 from "../../assets/news_4.jpg";
import Art5 from "../../assets/news_5.jpg";
import Art6 from "../../assets/news_6.jpg";
import { Link } from "react-router-dom";

class News_Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Blog"
    };
  }

  changeView = (e, target) => {
    this.setState({
      active: target,
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Kanzlei Elisabeth Lutz - Blog & News</title>
          <meta
            name="description"
            content="Kanzlei Elisabeth Lutz mit Fokus auf Familien- und Migrationsrecht"
          />
          <meta property="og:title" content="Kanzlei Elisabeth Lutz - Blog & News" />
          <meta
            property="og:description"
            content="Unser Blog liefert fundierte Informationen, praktische Ratschläge und die aktuellen
            Entwicklungen in den Bereichen Familienrecht und Migrationsrecht. Hier finden Sie Artikel
            und Fachbeiträge über aktuelle Entwicklungen zu rechtlich relevanten Fragen im
            Familienrecht und im Migrationsrecht."
          />
        </MetaTags>
        <NavDE />
        <div className="section first blog">
          <div className="heading1-cont flex center-v center-h">
            <div className="heading-deco m-right-3"></div>
            <h1 className="argesto">Blog und News</h1>
          </div>
          <div className="flex center-h">
            <button
              className={
                this.state.active === "Blog"
                  ? "default"
                  : "default not-active"
              }
              onClick={(event) => this.changeView(event, "Blog")}
            >
              Blog
            </button>
            <button
              className={
                this.state.active === "News" ? "default" : "default not-active"
              }
              onClick={(event) => this.changeView(event, "News")}
            >
              News
            </button>
          </div>
        </div>
        <div className="section flex intro center-h less-bot">
          <div className="medium flex">
            {this.state.active === "Blog" ?
              <div className="flex news-contt">
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/spurwechsel-im-auslaenderrecht-chancen-und-herausforderungen"
                >
                  <img src={News11}></img>
                  <span className="teal">Migrationsrecht</span>
                  <h4 className="align-left">Spurwechsel im Ausländerrecht</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">12.01.25</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/einreise-und-aufenthaltsverbot-verkuerzen-oder-aufheben"
                >
                  <img src={News10}></img>
                  <span className="teal">Migrationsrecht</span>
                  <h4 className="align-left">Einreise- und Aufenthaltsverbot verkürzen oder aufheben</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">19.12.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/zwangsvollstreckung-von-kindesunterhalt-nach-deutschem-recht"
                >
                  <img src={News9}></img>
                  <span className="teal">Familienrecht</span>
                  <h4 className="align-left">Zwangsvollstreckung von Kindesunterhalt</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">01.12.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/das-beschleunigte-fachkraefteeinwanderungsverfahren-nach-deutschem-recht-chancen-herausforderungen"
                >
                  <img src={News8}></img>
                  <span className="teal">Migrationsrecht</span>
                  <h4 className="align-left">Das beschleunigte Fachkräfteeinwanderungs-Verfahren</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">18.11.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/wann-steht-mir-trennungsunterhalt-zu"
                >
                  <img src={News7}></img>
                  <span className="teal">Familienrecht</span>
                  <h4 className="align-left">Wann steht mir Trennungsunterhalt zu?</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">08.07.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/haftbeschwerde"
                >
                  <img src={News5}></img>
                  <span className="teal">Sonstiges</span>
                  <h4 className="align-left">Haftbeschwerde</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">01.06.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/neues-staatsangehoerigkeitsgesetz"
                >
                  <img src={News6}></img>
                  <span className="teal">Familienrecht</span>
                  <h4 className="align-left">Neues Staatsangehörigkeitsgesetz</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">01.06.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/abschiebungen-deutschland-2024-kritische-betrachtung"
                >
                  <img src={News1}></img>
                  <span className="teal">Migrationsrecht</span>
                  <h4 className="align-left">Abschiebungen in Deutschland 2024: Eine kritische Betrachtung</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">10.03.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/darf-ich-eigenstaendig-aus-fluechtlingsunterkunft-ausziehen"
                >
                  <img src={News2}></img>
                  <span className="teal">Migrationsrecht</span>
                  <h4 className="align-left">Darf ich einfach selbstständig aus der Flüchtlingsunterkunft ausziehen?</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">10.03.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/was-kommt-bei-scheidung-auf-mich-zu-leitfaden-durch-prozess"
                >
                  <img src={News3}></img>
                  <span className="teal">Familienrecht</span>
                  <h4 className="align-left">Was kommt bei der Scheidung alles auf mich zu? Ein Leitfaden durch den Prozess</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">10.03.24</p>
                  </div>
                </Link>
                <Link
                  className="news-cont blog flex col center-v"
                  to="/de/blog/erwerbsobliegenheiten-bei-scheidung-ab-wann-muss-ich-arbeiten"
                >
                  <img src={News4}></img>
                  <span className="teal">Familienrecht</span>
                  <h4 className="align-left">Erwerbsobliegenheiten bei der Scheidung: Ab wann muss ich arbeiten?</h4>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kanzlei Lutz</p>
                    <p className="blog-inline right">10.03.24</p>
                  </div>
                </Link>
              </div> : <div className="flex news-contt">
                <a
                  className="news-cont news art flex col center-v center-h"
                  href="https://www.equallegalaid.org/wp-content/uploads/2023/08/ELA_JURISPRUDENCE-COLLECTION_2021-2023_DIGITAL.pdf"
                  target="_blank"
                >
                  <h4 className="align-left">Rechtsprechungsübersicht: Griechische Urteile bei der Umsetzung des EU-Rechts im
                    Asylrecht</h4>
                  <p className="full">Equal Legal Aid</p>
                  <img src={Art1}></img>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Thessaloniki, Griechenland</p>
                    <p className="blog-inline right">01.09.2023</p>
                  </div>
                </a>
                <a
                  className="news-cont news art flex col center-v center-h"
                  href="https://www.linkedin.com/connect-services/?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fposts%2Felisabeth-lutz-3ab9b1265_best-practices-to-provide-refugees-with-the-activity-7082714574809903104-gcm4%2F%3Futm_source%3Dshare%26amp%3Butm_medium%3Dmember_desktop"
                  target="_blank"
                >
                  <h4 className="align-left">Best practices of different countries to provide refugees with the right to work –
                    Bewährte Methoden verschiedener Länder, die Geflüchteten das Recht auf Arbeit
                    zuerkennen</h4>
                  <p className="full">Elisabeth Lutz, UNHCR</p>
                  <img src={Art2}></img>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Kuala Lumpur, Malaysia</p>
                    <p className="blog-inline right">01.05.2023</p>
                  </div>
                </a>
                <a
                  className="news-cont news art flex col center-v center-h"
                  href="https://www.linkedin.com/posts/equal-legal-aid_lawyer-team-dreamteam-activity-7122870230074208256-mY8U/?utm_source=share&amp;utm_medium=member_desktop"
                  target="_blank"
                >
                  <h4 className="align-left">Volontariat bei Equal Legal Aid</h4>
                  <p className="full">Equal Legal Aid</p>
                  <img src={Art3}></img>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Thessaloniki, Griechenland</p>
                    <p className="blog-inline right">01.11.2023</p>
                  </div>
                </a>
                <a
                  className="news-cont news art flex col center-v center-h"
                  href="https://www.equallegalaid.org/turning-a-blind-eye/"
                  target="_blank"
                >
                  <h4 className="align-left">Female Genital Mutilation – A wound carried by many asylum seekers – Sexuelle
                    Gewalt an Frauen – Genitalverstümmelung</h4>
                  <p className="full">Equal Legal Aid</p>
                  <img src={Art4}></img>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Thessaloniki, Griechenland</p>
                    <p className="blog-inline right">30.11.2023</p>
                  </div>
                </a>
                <a
                  className="news-cont news art flex col center-v center-h"
                  href="https://www.stuttgarter-zeitung.de/inhalt.abschiebung-am-mittwoch-verhindert-stuttgarter-wirt-kaempft-um-seinen-lagerarbeiter-aus-nigeria.f73d9119-56d4-4b8c-9d47-9b7bf5734c99.html#:~:text=Abschiebung%20am%20Mittwoch%20verhindert%20Stuttgarter%20Wirt%20k%C3%A4mpft%20um%20seinen%20Logistiker%20aus%20Nigeria&text=Der%20Stuttgarter%20Wirt%20Michael%20Schm%C3%BCcker%20schl%C3%A4gt%20Alarm.,um%20ihn%20nach%20Nigeria%20abzuschieben."
                  target="_blank"
                >
                  <h4 className="align-left">Abschiebung verhindert - Stuttgarter Wirt kämpft um seinen Logistiker aus Nigeria</h4>
                  <p className="full">Uwe Bogen</p>
                  <img src={Art5}></img>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Stuttgart, Deutschland</p>
                    <p className="blog-inline right">08.11.2023</p>
                  </div>
                </a>
                <a
                  className="news-cont news art flex col center-v center-h"
                  href="https://www.deutschlandfunk.de/migration-und-abschiebung-neues-gesetz-altes-kompetenzwirrwarr-dlf-15dcfe4f-100.html"
                  target="_blank"
                >
                  <h4 className="align-left">Neues Gesetz, altes Kompetenzwirrwarr – Interview von Rechtsanwältin Lutz beim
                    Deutschlandfunk Hintergrund</h4>
                  <p className="full">Katharina Thoms</p>
                  <img src={Art6}></img>
                  <div className="flex row max-w">
                    <p className="blog-inline left">Berlin, Deutschland</p>
                    <p className="blog-inline right">24.11.2023</p>
                  </div>
                </a>
              </div>}
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default News_Overview;
