import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import "../../stylesheets/FooterDE.css";

class FooterDE extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="flex center-h">
        <div className="section footer">
          <div className="bottom-divider footer"></div>
          <div className="flex spa">
            <div className="flex footer-third leftmost col">
              <span className="footer-header argesto">Kanzlei Elisabeth Lutz</span>
              <span>Olgastraße 57a</span>
              <span>70182 Stuttgart</span>
              <a className="quote-link" href="tel:07112364402" >0711 - 2364402</a>
              <a className="quote-link" href="tel:015142800153" >0151 - 42800153</a>
              <a className="quote-link" href="mailto:info@kanzlei-e-lutz.de">info@kanzlei-e-lutz.de</a>
            </div>
            <div className="flex footer-third col">
              <span className="footer-header argesto">Information</span>
              <Link reloadDocument to="/de/familienrecht">
                <span>Familienrecht</span>
              </Link>
              <Link reloadDocument to="/de/migrationsrecht">
                <span>Migrationsrecht</span>
              </Link>
              <Link reloadDocument to="/de/pflichtanwalt">
                <span>Pflichtanwalt</span>
              </Link>
              <Link reloadDocument to="/de/blog">
                <span>Blog</span>
              </Link>
              <Link reloadDocument to="/de/glossar">
                <span>Glossar</span>
              </Link>
            </div>
            <div className="flex footer-third right-most col">
              <span className="footer-header argesto">Verweise</span>
              <Link reloadDocument to="/de/kontakt">
                <span>Kontakt</span>
              </Link>
              <Link reloadDocument to="/de/formulare">
                <span>Formulare</span>
              </Link>
              <Link reloadDocument to="/de/faq">
                <span>FAQ</span>
              </Link>
              <Link reloadDocument to="/de/impressum">
                <span>Impressum</span>
              </Link>
              <Link reloadDocument to="/de/datenschutzerklaerung">
                <span>Datenschutz</span>
              </Link>
            </div>
          </div>
          {/* <div className="bottom-divider footer"></div> */}
          <div className="footer-top flex center-h">
            <span className="argesto footer-copyright">
              ©2025 Kanzlei Elisabeth Lutz
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default FooterDE;
